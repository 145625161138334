import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Container, Heading, Text } from 'theme-ui'
import Author from '~/components/Article/Author'

const ArticlePage = ({ article }) => {
  const { title, author, body, image } = article

  return (
    <Container sx={{
      marginTop: '1.5rem',
      padding: '0 1.5rem',
      maxWidth: '850px',
    }}>
      <Heading
        as="h2"
        sx={{
          color: '#851B54',
          fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
          fontWeight: 'normal',
          fontSize: ['34px', '55px', '55px'],
          marginTop: ['16px', null, '36px'],
          marginBottom: ['16px', null, '32px'],
          textAlign: ['left', 'center'],
        }}
      >
        {title}
      </Heading>

      {image && (
        <Box sx={{ width: '100%' }}>
          <Img
            fluid={image.fluid}
            alt={image.title}
            sx={{
              height: '100%',
              minHeight: '100px',
              maxHeight: ['240px', '280px', '450px']
            }}
          />
        </Box>
      )}

      {author && <Author name={author.name} />}

      <Text
        sx={{
          fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
          fontSize: ['14px', '16px', '16px'],
          marginBottom: '3rem',
          textAlign: 'left',
          '& p': {
            img: {
              width: '100%'
            }
          }
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html
          }}
        />
      </Text>
    </Container>
  )
}

ArticlePage.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    slug: PropTypes.string.isRequired,
    body: PropTypes.shape({}),
    description: PropTypes.shape({}),
    image: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    }),
    author: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string
      }),
      bio: PropTypes.shape({})
    })
  })
}

export default ArticlePage
